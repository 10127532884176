<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" @click.native="change">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/news'}">新闻报道</el-breadcrumb-item>
        <el-breadcrumb-item>2019年“创客中国”中小企业创新创业大赛暨”创客北京2019“创新创业大赛</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="left">
        <h2>优质项目助发展 2019年“创客中国”中小企业创新创业大赛暨”创客北京2019“创新创业大赛</h2>
        <div class="second">
          <div class="time-img"><img src="@/assets/img/time.jpg" alt=""></div>
          <span class="time">2019/1/15</span>
          <span class="time-title">“创客中国”中小企业创新创业大赛</span>
          <span class="gold">三等奖
            <img src="@/assets/img/media.png" alt="">
          </span>
        </div>
        <p class="third-p1">为贯彻落实《中央办公厅国务院办公厅关于促进中小企业健康发展的指导意见》精神，进一步助力北京市构建高精尖经济结构，推动中小企业双创升级，6月12日，北京市经济和信息化局、北京市财政局联合发布新的双创赛事品牌——“创客北京”，并在北京经济技术开发区召开2019年“创客中国”北京市中小企业创新创业大赛暨“创客北京2019”创新创业大赛启动会。</p>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/kai.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/kai1.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/kai2.jpg" alt=""></div>
        </div>
        <div class="firth">
          <div><img src="@/assets/img/kai3.jpg" alt=""></div>
        </div>
        <p class="fourth-p3">北京市经济和信息化局姜广智副巡视员表示，本届大赛以“围绕产业链，部署创新链，配置资金链”为主旨，着力打造为中小企业和创客群体提供交流展示、产融对接、项目孵化的平台。市经济和信息化局将为参赛的优秀企业和团队提供全方位服务，将优秀项目纳入重点培育库，提供定制化创业资源和发展壮大的“服务包”，力争培育一批细分领域“专精特新”和“小巨人”企业。</p>
        <p class="fourth-p3">据悉，本届大赛由工业和信息化部、财务部指导，北京市经济和信息化局、北京市财政局主办，北京市中小企业服务中心、北京市中小企业公共服务平台承办。大赛面向十大高精尖产业、文化创意产业、新型便民服务业等三大领域。分为企业组和创客组，设初赛、复赛、决赛三个环节，在110家市级中小企业公共服务示范平台和小型微型企业创业创新示范基地设初赛点，在北京经济技术开发区以及东城、海淀、丰台、门头沟、房山、顺义、大兴、昌平、平谷、怀柔、延庆等11个区设立分赛区（复赛点）。本次大赛决赛将在8月举行，分别决出企业组和创客组特等奖、一等奖、二等奖、三等奖。</p>
        <p class="fourth-p2">工信部中小企业局、财政部，市科委、市教委、市商务局、市人力资源社会保障局、市知识产权局、市金融监管局、市工商联、人民银行营管部等市级部门、各区中小企业工作主管部门，金融机构、示范平台、示范基地相关负责人，以及创新创业企业代表等200家单位共计300余人出席启动会。</p>
        <p class="fourth-p2">大赛将分为企业组和创客组，在110家市级中小企业公共服务示范平台和小型微型企业创业创新示范基地设初赛点，在北京经济技术开发区以及东城、海淀、丰台、门头沟、房山、顺义、大兴、昌平、平谷、怀柔、延庆等11个区设立复赛点。</p>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/wanggang.jpg" alt=""></div>
        </div>
        <p class="fourth-center">北京市经济和信息化局 党委书记，局长发表讲话</p>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/zhihui.jpg" alt=""></div>
        </div>
        <p class="fourth-center">工业和信息化部 中小局 副局长秦志辉发表讲话</p>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/tedeng.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/yideng.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/erdeng.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/sandeng.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/sandeng2.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:1rem">
          <div><img src="@/assets/img/zongjie.jpg" alt=""></div>
        </div>
        <p style="text-align:right;margin-top:2rem;margin-bottom:0rem">部分内容取自：<a href="http://www.sohu.com/a/320315289_99947076">http://www.sohu.com/a/320315289_99947076</a></p>
      </div>

      <div class="right">
        <h3>相关报道</h3>
        <ul>
          <li>
            <a href="newone"><img src="@/assets/img/chuangfu6.jpg" alt=""></a>
            <div class="middle">
              <a href="newone" class="h4">2019创富中国“智能+”专场活动在成都成功举办</a>
              <span class="middle-p1">创富中国年度总决赛</span>
              <span class="middle-p2">冠军
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/5/30 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="neweight"><img src="@/assets/img/867.jpg" alt=""></a>
            <div class="middle">
              <a href="neweight" class="h4">中国（小谷围）人工智能创新创业大赛圆满落下帷幕</a>
              <span class="middle-p1">人工智能创新创业比赛</span>
              <span class="middle-p2">优胜奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/10 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newseven"><img src="@/assets/img/869.jpg" alt=""></a>
            <div class="middle">
              <a href="newseven" class="h4">创新创业大赛|决赛圆满告捷，冠军花落谁家</a>
              <span class="middle-p1">青年人才创新创业大赛</span>
              <span class="middle-p2">第三名
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/3/29 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      currentRouter: state => state.nav.currentRouter
    })
  },
  methods: {
    ...mapActions({
      changeNavActive: 'nav/changeNavActive'
    }),
    change() {
      this.changeNavActive('首页')
      console.log(1)
    }
  }

}
</script>

<style lang="scss" scoped>
.fourth-center{
  text-align: center;
}
p{
    text-align: left;
    text-indent:2em;
}
img{
     display: block;
}
.top{
  margin-top: 65px;
  // padding: 0 205px;
  padding-left: 5%;
  width: 100%;
  height: 70px;
  background-color: #f2f2f2;
}
.el-breadcrumb {
    line-height: 70px;
}

.main{
    margin: auto;
    padding: 30px;
    width: 1420px;
    overflow: hidden;
}
.left{
    width: 65%;
    padding: 30px;
    float: left;
    overflow: hidden;
    background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(4, 0, 0, 0.15);
    border-radius: 20px;
    .second{
        overflow: hidden;
        margin-top: 40px;
    }
    .time-img{
        width: 20px;
        height: 20px;
        float: left;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .time{
        float: left;
        margin-left:10px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #999999;
    }
    .time-title{
        background-color: #eff3f5;
        border-radius: 4px;
        float: left;
        font-size: 14px;
        padding: 8px 11px 8px 9px;
        line-height: 10px;
        letter-spacing: 1px;
        color: #999999;
        margin-left: 40px;
        margin-right: 20px;
    }
    .gold{
    background-color: #ffd5d5;
    float: left;
    border-radius: 4px;
	font-size: 14px;
    padding: 8px 11px 8px 9px;
    line-height: 10px;
	letter-spacing: 1px;
    color: #ff3333;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: -14px;
    }
    }
    .third-p1{
    margin-top: 60px;
    font-size: 16px;
	line-height: 30px;
	letter-spacing: 0px;
	color: #666666;
    }
    .fourth{
        padding: 0 14px;
        div img{
            width: 375px;
            height: 251px;
        }
        .fourth-img1{
            margin-right: 14px;
            float: left;
        }
    }
       .fourth-p2{
           margin-top: 16px;
            font-family: PingFang-SC-Bold;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .fourth-p3{
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .firth{
            padding: 0 14px;
            div img{
                width: 100%;
                height: 511px;
            }
        }
}
ul{
  padding: 0
}
.right{
    width: 21.5%;
    margin-left: 25px;
    float: left;
    overflow: hidden;
    h3{
    font-size: 26px;
	line-height: 48px;
	letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-left: 30px;
    position: relative;
    }
    h3::before{
        display: block;
        content: '';
        width: 6px;
        height: 33px;
        background-color: #ff3333;
        position: absolute;
        top: 8px;
        left: -20px;
    }
    ul li{
        width: 100%;
        padding: 10px;
        background-color: #eff3f5;
        border-radius: 8px;
        margin: 15px 0;
        overflow: hidden;
        img{
            width: 100%;
            height: 230px;
            border-radius: 8px;
        }
        .middle{
          width: 100%;
          margin-left: 8px;
          overflow: hidden;
          margin-bottom: 25px;
          span{
              float: left;
              padding: 0 9px;
          }
          .middle-p1{
              background-color: #ffffff;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #999999;
          }
          .middle-p2{
              background-color: #ffd5d5;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #ff3333;
              margin-left: 25px;
              position: relative;
              img{
                  width: 20px;
                  height: 26px;
                  position: absolute;
                  top: 1px;
                  left: -15px;
              }
          }
        }
        .h4{
          display: block;
            width: 90%;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            margin: 16px 0;
        }
        .time{
            overflow: hidden;
            margin-right: 8px;
            img{
                width: 20px;
                height: 20px;
                float: right;
            }
            .time-word{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1px;
                color: #999999;
                float: right;
                margin-left: 15px;
                margin-right: 5px;
            }
        }
    }
}
a:hover{
      text-decoration:none;
      color: #e45151  !important;
      transition: all 0.3s;
}
</style>
